import React from 'react';

const textarea = (props) => (
    <textarea type={props.type}
        className="form-control form-control-lg"
        rows="10"
        name={props.fieldMeta.key}
        id={props.fieldMeta.key}
        onChange={props.formMeta.handleChange}
        onBlur={props.formMeta.handleBlur}
        value={props.formMeta.values[props.fieldMeta.key]}
        placeholder={props.fieldMeta.placeholder}
    ></textarea>
);

export default textarea;
