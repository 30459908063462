export const submitForm = async (url, payload) => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept":"application/json"
            },
            body: JSON.stringify(payload),
        })
        if (!response.ok) {
            //not 200 response
            throw response;
        }
        return { success: true, message: "" };
    } catch (e) {
        console.log(e);
        throw e;
    }
}
