import React, { useState } from "react";
import Form from '../Form/';
import CountryOptions from "./json/country";
import { FormFields } from './json/form.js';
import { submitForm } from '../Services/submitform';
import "./prefooter.scss";

export default function Prefooter(props) {
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const renderForm = (props) => {
    const formFieldProps = {
      "countryOptions": CountryOptions
    }
    const formFields = FormFields(formFieldProps);
    return (
      <div className="prefooter-inner">
        <div className="section-head">
          <h2 className="section-title">Join <em>the list</em></h2>
          <p className="section-desc">Be the first to know what's coming.</p>
        </div>
        <div className="prefooter-form">
          <Form
            id="prefooter"
            onSubmit={async (values) => {
              console.log('values', values);
              const SIGNUP_URL = process.env.GATSBY_SIGNUP_URL;
              await submitForm(SIGNUP_URL, values)
              setShowThankYouMessage(true);
            }}
            fields={formFields}
            DEVICE_VIEW={props.DEVICE_VIEW} />
          <p className="form-helper">By submitting this form, you are consenting to receive emails from Via Flagler by The Breakers and The Breakers Palm Beach.</p>
        </div>
      </div>
    )
  }
  const renderThankyouMessage = () => {
    return (
      <div className="form-response">
        <h2>Thank <em>you</em></h2>
        <p>Be on the lookout for our emails!</p>
      </div>
    )
  }
  return (
    <div className="prefooter">
      <div className="container">
        {!showThankYouMessage && renderForm(props)}
        {showThankYouMessage && renderThankyouMessage()}
      </div>
    </div>
  );

}
