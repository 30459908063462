import React from "react";
import { Link } from "gatsby";
import "./navigationlinks.scss";

const renderCtaNavigationLink = (navItem) => {
    const targetAtt = navItem.openInNewWindow ? "_blank" : "_self";
    const linkUrl = navItem.navigateToPage && navItem.navigateToPage.slug ? navItem.navigateToPage.slug : navItem.url;
    return (
      <a href={linkUrl} target={targetAtt}>
        {navItem.text}
      </a>
    )
}

const renderPageNavigationLink = (navItem) => {
    return (
        <Link activeClassName="active" to={`/${navItem.slug}`}>
            {navItem.name}
        </Link>
    )
}

const getListItems = (links) => {
    if (!links) {
        return null;
    }
    return links.map((navItem, index) => {
        const isCta = navItem.text;
        return (
            <li key={index}>
                {isCta && renderCtaNavigationLink(navItem)}
                {!isCta && renderPageNavigationLink(navItem)}
            </li>
        );
    })
}

const getMobileNavigationLinks = (props) => {
    return (
        <div className="footer-navbar-wrap">
            <ul className="footer-navbar list-unstyled">
                {getListItems(props.siteSettings.footerLeftNavigationLinks)}
                {getListItems(props.siteSettings.footerMiddleNavigationLinks)}
                {getListItems(props.siteSettings.footerRightNavigationLinks)}
            </ul>
        </div>
    );
}

export default function getNavigationLinks(props) {
    if (props.DEVICE_VIEW.IsMobile) {
        return getMobileNavigationLinks(props);
    }
    return (
        <div className="footer-navbar-wrap">
            <ul className="footer-navbar list-unstyled">
                {getListItems(props.siteSettings.footerLeftNavigationLinks)}
            </ul>
            <ul className="footer-navbar list-unstyled">
                {getListItems(props.siteSettings.footerMiddleNavigationLinks)}
            </ul>
            <ul className="footer-navbar list-unstyled">
                {getListItems(props.siteSettings.footerRightNavigationLinks)}
            </ul>
        </div>
    );
}