
export const FormFields = (props) => {
    return [
        {
            "key": "firstname",
            "value": "",
            "label": "First Name",
            "type": "text",
            "placeholder": "First Name",
            "validations": ["required"],
            "styleClasses": ["col-md-6 col-lg-3"]
        },
        {
            "key": "lastname",
            "value": "",
            "label": "Last Name",
            "type": "text",
            "placeholder": "Last Name",
            "validations": ["required"],
            "styleClasses": ["col-md-6 col-lg-3"]
        },
        {
            "key": "email",
            "value": "",
            "label": "Email Address",
            "type": "text",
            "placeholder": "Email Address",
            "validations": ["required", "email"],
            "styleClasses": ["col-md-6 col-lg-3"]
        },
        {
            "key": "country",
            "label": "Country",
            "type": "select",
            "options": props.countryOptions,
            "value": "US",
            "validations": [],
            "styleClasses": ["col-md-6 col-lg-3"]
        }
    ]
}
