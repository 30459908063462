import React, { Component } from "react"
import Header from "../Header/header"
import Footer from "../Footer/footer"
import { isLoggedIn } from "../Services/auth"

import "../../assets/sass/style.scss"
import "./layout.scss"
class layout extends Component {

  shouldShowContent() {
    let showContent = true
    if (this.props.children.props !== undefined) {
      const authenticationRequired = this.props.children.props.pageContext.page.authenticationRequired
      showContent = authenticationRequired ? isLoggedIn() : true
    }
    return showContent;
  }
  getMainWrapperStyle = () => {
    let obj = {};
    const PAGES_WITHOUT_MIN_HEIGHT = ["404", "thank-you"];
    if (PAGES_WITHOUT_MIN_HEIGHT.indexOf(this.props.page.slug) === -1) {
      obj.minHeight = "100vh";
    }
    return obj
  }
  render() {
    const { children, siteSettings, DEVICE_VIEW } = this.props
    if (!siteSettings)
      return <div>{children}</div>

    return (
      <div className="layout">
        <Header mainNavigationLinks={siteSettings.mainNavigationLinks} utilityNavigationLinks={siteSettings.utilityNavigationLinks} DEVICE_VIEW={DEVICE_VIEW} />
        {this.shouldShowContent() && <main className="main" style={this.getMainWrapperStyle()}>
          {children}
        </main>}
        <Footer siteSettings={siteSettings} DEVICE_VIEW={DEVICE_VIEW} />
      </div>
    )
  }
}

export default layout
