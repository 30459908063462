import React from 'react';

const file = (props) => (
    <input type={props.type}
        className="form-control form-control-lg"
        name={props.fieldMeta.key}
        id={props.fieldMeta.key}
        onChange={e => props.formMeta.setFieldValue(props.fieldMeta.key, e.currentTarget.files)}
        onBlur={props.formMeta.handleBlur}
        placeholder={props.fieldMeta.placeholder}
        multiple={props.fieldMeta.multiple}
        accept={props.fieldMeta.accept}
    />
);

export default file;
