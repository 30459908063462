import React from 'react';
import { Select, Input, Textarea, File } from './controls';
import './formfield.scss';

const formField = (props) => {
  const { field, formMeta } = props;
  if (field.type === 'section-header')
    return (
      <div className={`pt-2 ${[...props.field.styleClasses].join(' ')}`}>
        <h3>{props.field.value}</h3>
      </div>
    );
  return (
    !props.field.hidden && (
      <div
        className={`form-group ${[
          ...props.field.styleClasses,
          props.formMeta.touched.hasOwnProperty(props.field.key) &&
          props.formMeta.errors.hasOwnProperty(props.field.key)
            ? 'error'
            : null,
        ].join(' ')}`}
      >
        <label className="form-label">
          {props.field.label}{' '}
          {props.field.validations.includes('required') && '*'}
        </label>
        {/* BEGIN: SELECT */}
        {props.field.type === 'select' && (
          <Select
            type={props.field.type}
            fieldMeta={props.field}
            formMeta={props.formMeta}
          />
        )}
        {/* END: SELECT */}
        {/* BEGIN: INPUT */}
        {props.field.type === 'text' && (
          <Input
            type={props.field.type}
            fieldMeta={props.field}
            formMeta={props.formMeta}
          />
        )}
        {/* END: INPUT */}
        {/* BEGIN: TEXTAREA */}
        {props.field.type === 'textarea' && (
          <Textarea
            type={props.field.type}
            fieldMeta={props.field}
            formMeta={props.formMeta}
          />
        )}
        {/* END: TEXTAREA */}
        {/* BEGIN: FILE */}
        {props.field.type === 'file' && (
          <File
            type={props.field.type}
            fieldMeta={props.field}
            formMeta={props.formMeta}
          />
        )}
        {/* END: FILE */}
        {formMeta.errors[field.key] && formMeta.touched[field.key] ? (
          <div className="form-field-reponse text-danger">
            {formMeta.errors[field.key][0]}
          </div>
        ) : null}
      </div>
    )
  );
};

export default formField;
