import React from 'react';

const select = (props) => {
    return (
        <select
            className={`custom-select custom-select-lg`}
            name={props.fieldMeta.key}
            id={props.fieldMeta.key}
            defaultValue={props.formMeta.values[props.fieldMeta.key]}
            onChange={e => {
                props.formMeta.handleChange(e)
                if (props.fieldMeta.handleChange) {
                    props.fieldMeta.handleChange(e, props.formMeta)
                }
            }}
            onClick={e => {
                if (props.fieldMeta.handleClick) {
                    props.fieldMeta.handleClick(e, props.formMeta)
                }
            }}
            onBlur={props.formMeta.handleBlur}
        >
            {props.fieldMeta.placeholder && <option value="" disabled>
                {props.fieldMeta.placeholder}
            </option>}
            {props.fieldMeta.options.map(
                (option, optIndex) => (
                    <option
                        key={optIndex}
                        value={option.value}>
                        {option.title}
                    </option>
                )
            )}
        </select>
    );
}


export default select;
