import React from "react";
import "./footer.scss";
import Prefooter from "../Prefooter/prefooter"
import getNavigationLinks from "./navigationlinks";

export default function Footer(props) {

  const getContactsView = (siteName, footerAddress) => {
    return (
      <div className="contact-info">
        <h6 className="contact-info-title">{siteName}</h6>
        <p>{footerAddress}</p>
      </div>
    );
  }

  return (
    <>
      <Prefooter siteSettings={props.siteSettings} DEVICE_VIEW={props.DEVICE_VIEW} />
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="footer-top-wrap">
              <div className="row">
                <div className="footer-left col-lg">
                  <div className="social-box">
                    <h6 className="social-box-title">CONNECT WITH US</h6>
                    <a href={props.siteSettings.instagramUrl} className="social-link left-pad" rel="noopener noreferrer" target="_blank">
                      <i className="icon icon-instagram"></i>
                    </a>
                    <a href={props.siteSettings.facebookUrl} className="social-link" rel="noopener noreferrer" target="_blank">
                      <i className="icon icon-facebook"></i>
                    </a>
                  </div>
                </div>
                <div className="footer-center col-lg-6">
                  {getNavigationLinks(props)}
                </div>
                <div className="footer-right col-lg-auto text-lg-right">
                  {getContactsView(props.siteSettings.siteName, props.siteSettings.footerAddress)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-wrap">
              <div className="footer-text">
                © {new Date().getFullYear()} The Breakers Palm Beach, FL. All Rights Reserved.
              </div>
              <div className="footer-links">
                <a href={props.siteSettings.privacyPolicy}>Privacy Policy</a>
                <span className="sep">|</span>
                <a href={props.siteSettings.termsOfUse}>Terms of Use</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
