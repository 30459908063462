import React from 'react';

const input = (props) => (
    <input type={props.type}
        className="form-control form-control-lg"
        name={props.fieldMeta.key}
        id={props.fieldMeta.key}
        onChange={props.formMeta.handleChange}
        onBlur={props.formMeta.handleBlur}
        value={props.formMeta.values[props.fieldMeta.key]}
        placeholder={props.fieldMeta.placeholder}
    />
);

export default input;
