import React, { useState } from 'react';
import { Formik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import FormField from './formfield';
import scrollToElement from 'scroll-to-element';
import { Spinner } from 'react-bootstrap';
/**
 * @name setValidationMsg
 * @param {string} msg Validation msg that needs to be added.
 * @param {object} errorObj Object in which all error are residing.
 * @param {string} valueKey Key of that value that needs to be checked.
 * @desc Sets validation msg in object.
 * @return {object} Object that can be replaced in front of that value.
 */
const setValidationMsg = (validationMsg, valueKey, errorObj) => {
  if (errorObj.hasOwnProperty(valueKey)) {
    errorObj[valueKey].push(validationMsg);
  } else {
    errorObj[valueKey] = [validationMsg];
  }
};

/**
 * @name validateFields
 * @param {object} values
 * @desc Validate's values based on custom conditions and cases.
 * @return {object} errors
 */
const validateFields = (values, props, setFocus, updateSetFocus) => {
  const errors = {};
  Object.keys(values)
    // valueKey: key of current index iteration.
    .forEach((valueKey, index) => {
      const field = props.fields.find((_field) => _field.key === valueKey);
      field.validations
        // validation: string of validation type
        .forEach((validation) => {
          switch (validation) {
            case 'required':
              if (!values[valueKey]) {
                setValidationMsg(
                  `${field.label} is required`,
                  valueKey,
                  errors
                );
              }
              break;
            case 'email':
              if (
                values[valueKey] &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values[valueKey]
                )
              )
                setValidationMsg(`${field.label} is invalid`, valueKey, errors);
              break;
            case 'phone':
              if (
                values[valueKey] &&
                !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(
                  values[valueKey]
                )
              )
                setValidationMsg(`${field.label} is invalid`, valueKey, errors);
              break;
            case 'url':
              if (
                values[valueKey] &&
                !/^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/g.test(
                  values[valueKey]
                )
              )
                setValidationMsg(`${field.label} is invalid`, valueKey, errors);
              break;
            default:
          }
        });
    });
  if (errors && setFocus) {
    const formId = props.id;
    const fieldId = Object.keys(errors)[0];
    const elm = document.querySelector(`form[id='${formId}'] #${fieldId}`);
    const navEl = document.querySelector('.header');
    if (elm) {
      scrollToElement(elm, {
        duration: 200,
        offset: -(navEl.scrollHeight + 50),
      });
      elm.focus();
      updateSetFocus(false); //reset to false for not focusing unless user hits submit again
    }
  }
  return errors;
};

/**
 * @name getInitialValues
 * @desc Creates a payload for initial values that can be further used
 * in conjunction with formik.
 * @return {object} initialValues
 */
const getInitialValues = (props) => {
  const initialValues = {};
  props.fields.forEach(
    // SETTING FIELD VALUES WITH RESPECT TO ITS KEY
    // IN OBJECT
    (field) => {
      if (!field.key) return null;
      initialValues[field.key] = field.value;
    }
  );
  return initialValues;
};

const Form = (props) => {
  const [setFocus, updateSetFocus] = useState(false);
  const recaptchaRef = React.useRef();
  return (
    <Formik
      initialValues={getInitialValues(props)}
      validate={(values) =>
        validateFields(values, props, setFocus, updateSetFocus)
      }
      onSubmit={async (values, actions) => {
        try {
          const token = await recaptchaRef.current.executeAsync();
          if (token) {
            await props.onSubmit(values);
          } else {
            actions.setStatus({
              msg: 'Something went wrong. Please try again later.',
            });
          }
        } catch (e) {
          console.error(e);
          actions.setStatus({
            msg: 'Something went wrong. Please try again later.',
          });
        }
      }}
    >
      {(formMeta) => (
        <form id={props.id} className="row" onSubmit={formMeta.handleSubmit}>
          {props.fields.map((field, index) => {
            return (
              <React.Fragment key={index}>
                <FormField field={field} formMeta={formMeta} />
              </React.Fragment>
            );
          })}
          <div className="col-md-12">
            {!formMeta.isSubmitting &&
            formMeta.status &&
            formMeta.status.msg ? (
              <div className="form-group text-danger">
                {formMeta.status.msg}
              </div>
            ) : (
              <div className="form-group form-label">* Required field</div>
            )}
            <div>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              />
            </div>
            <div className="form-group btn-wrapper">
              <button
                type="submit"
                className="btn"
                onClick={() => {
                  updateSetFocus(true);
                }}
                disabled={formMeta.isSubmitting}
              >
                {!formMeta.isSubmitting && <>SUBMIT</>}
                {formMeta.isSubmitting && (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">SUBMITTING ...</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Form;
