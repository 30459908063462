import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "gatsby";
import { isLoggedIn, logout } from "../Services/auth";
import logo from "../../../static/logo.svg";
import "./header.scss";

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current)
      setSticky(ref.current.getBoundingClientRect().top <= 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const renderCtaUtilityNavigationLink = (navItem) => {
    const targetAtt = navItem.openInNewWindow ? "_blank" : "_self";
    const linkUrl = navItem.navigateToPage && navItem.navigateToPage.slug ? navItem.navigateToPage.slug : navItem.url;
    return (
      <a href={linkUrl} target={targetAtt}>
        {navItem.text}
      </a>
    )
  }

  const renderPageUtilityNavigationLink = (navItem) => {
    return (
      <Link activeClassName="active" to={`/${navItem.slug}`}>
        {navItem.name}
      </Link>
    )
  }

  const renderLogout = () => {
    return (
      <li>
        <a href="#"
          onClick={event => {
            event.preventDefault()
            logout(() => navigate(`/`))
          }}>
          Logout
        </a>
      </li>
    )
  }

  const renderUtilityNavigation = () => {
    return (
      <ul className="top-navbar-nav list-unstyled">
        {props.utilityNavigationLinks &&
          props.utilityNavigationLinks.map((navItem, index) => {
            const isCta = navItem.text;
            return (
              <li key={index}>
                {isCta && renderCtaUtilityNavigationLink(navItem)}
                {!isCta && renderPageUtilityNavigationLink(navItem)}
              </li>
            );
          })}
          {isLoggedIn() && renderLogout()}
      </ul>
    )
  }

  return (
    <header className="header" id="header" ref={ref}>
      <div className={`header-inner ${isMenuOpen ? 'menu-open' : ''}`}>
        {props.DEVICE_VIEW.IsDesktop && <section className="top-navbar">
          <div className="container">
            <div className="top-navbar-wrap">
              {renderUtilityNavigation()}
            </div>
          </div>
        </section>}
        <section className={`navbar ${isSticky ? ' active' : ''}`}>
          <div className="container">
            <div className="navbar-wrap">
              <Link to="/" className="navbar-brand">
                <img src={logo} alt="Flagler Logo" className="logo"></img>
              </Link>
              <div className="navbar-col">
                <ul className="navbar-nav list-unstyled">
                  {props.mainNavigationLinks &&
                    props.mainNavigationLinks.map((navItem, index) => {
                      return (
                        <li key={index}>
                          <Link to={`/${navItem.slug}`} activeClassName="active">
                            <span>{navItem.name}</span>
                            <i className="icon icon-arrow-right"></i>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                {!props.DEVICE_VIEW.IsDesktop && renderUtilityNavigation()}
              </div>
              {!props.DEVICE_VIEW.IsDesktop && 
                <button className="reset-btn navbar-toggler" onClick={toggleMenu}>
                  <i className={`icon icon-toggle-${isMenuOpen ? 'on' : 'off'}`}></i>
                </button>
              }
            </div>
          </div>
        </section>
      </div>
    </header>
  );
};